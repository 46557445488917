import React from 'react';
import './documentSharing.scss';
import CollaborationIcon from '../../Assets/images/home.png'; // Exemple d'icône de collaboration
import SecurityIcon from '../../Assets/images/home.png'; // Exemple d'icône de sécurité
import AccessibilityIcon from '../../Assets/images/home.png'; // Exemple d'icône d'accessibilité

const DocumentSharing = () => {
    return (
        <div className="document-sharing-container">
            <section className="intro-section">
                <h1>Partage de Documents</h1>
                <p>Découvrez comment notre solution SaaS facilite la collaboration en permettant un partage de documents simple et sécurisé, accessible à tous les membres de votre équipe où qu'ils soient.</p>
            </section>
            <section className="features-section">
                <h2>Fonctionnalités Clés</h2>
                <div className="feature">
                    <img src={CollaborationIcon} alt="Collaboration" />
                    <h3>Facilitation de la Collaboration</h3>
                    <p><strong>Améliorez la collaboration</strong> entre les membres de votre équipe grâce à notre outil de partage de documents. Travaillez ensemble en temps réel et assurez une communication fluide et efficace.</p>
                </div>
                <div className="feature">
                    <img src={SecurityIcon} alt="Sécurité" />
                    <h3>Sécurité des Documents</h3>
                    <p><strong>Assurez la sécurité de vos documents</strong> avec notre solution. Profitez d'un chiffrement avancé et de contrôles d'accès personnalisés pour protéger vos données sensibles.</p>
                </div>
                <div className="feature">
                    <img src={AccessibilityIcon} alt="Accessibilité" />
                    <h3>Accessibilité à Distance</h3>
                    <p><strong>Accédez à vos documents</strong> de n'importe où et à tout moment. Notre plateforme garantit que votre équipe peut travailler de manière flexible, que ce soit au bureau, à domicile ou sur le terrain.</p>
                </div>
            </section>
            <section className="cta-section">
                <h2>Prêt à Révolutionner la Collaboration de Votre Équipe ?</h2>
                <p>Inscrivez-vous pour une démo gratuite et découvrez comment notre solution de partage de documents peut transformer la collaboration au sein de votre entreprise.</p>
                <button onClick={() => console.log('Rediriger vers la démo')}>Demander une démo</button>
            </section>
        </div>
    );
};

export default DocumentSharing;
