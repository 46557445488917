import React, { useState } from 'react';
import { Menu as MenuIcon, Close as CloseIcon, Home as HomeIcon, Info as InfoIcon, Build as BuildIcon, QueryBuilder as QueryBuilderIcon, Folder as FolderIcon, ContactMail as ContactMailIcon, Assignment as AssignmentIcon } from '@mui/icons-material';
import './topBarMobile.scss';
import { Link } from 'react-router-dom';

const TopBarMobile = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <header className="top-bar-mobile">
            <div className="logo">
                <Link to="/">V I S I O N</Link>
            </div>
            <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
                {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </div>
            {isMobileMenuOpen && (
                <nav className="mobile-menu">
                    <ul>
                        <li>
                            <Link to="/overview" onClick={toggleMobileMenu}>
                                <HomeIcon className="menu-icon" />Résumé
                            </Link>
                        </li>
                       {/* <li>
                            <Link to="/project-tracking" onClick={toggleMobileMenu}>
                                <BuildIcon className="menu-icon" />Suivi de Chantier
                            </Link>
                        </li>
                        <li>
                            <Link to="/time-tracking" onClick={toggleMobileMenu}>
                                <QueryBuilderIcon className="menu-icon" />Relevé d'Heures
                            </Link>
                        </li>
                        <li>
                            <Link to="/document-sharing" onClick={toggleMobileMenu}>
                                <FolderIcon className="menu-icon" />Partage de Documents
                            </Link>
                        </li>*/}
                        <li>
                            <Link to="/about" onClick={toggleMobileMenu}>
                                <InfoIcon className="menu-icon" />À propos
                            </Link>
                        </li>
                        <li>
                            <Link to="/become-a-tester" onClick={toggleMobileMenu}>
                                <AssignmentIcon className="menu-icon" />Devenir Testeur
                            </Link>
                        </li>
                        <li>
                            <Link to="/request-demo" onClick={toggleMobileMenu}>
                                <ContactMailIcon className="menu-icon" />Demander une démo
                            </Link>
                        </li>
                        <li>
                            <button onClick={() => window.location.href = 'https://app.vision-team-build.com'}>
                                Connexion
                            </button>
                        </li>
                    </ul>
                </nav>
            )}
        </header>
    );
};

export default TopBarMobile;
