import React from 'react';
import './projectTracking.scss';
import AutomationIcon from '../../Assets/images/home.png'; // Exemple d'icône d'automatisation
import OptimizationIcon from '../../Assets/images/home.png'; // Exemple d'icône d'optimisation
import UIUXIcon from '../../Assets/images/home.png'; // Exemple d'icône UI/UX
import { Link } from 'react-router-dom';

const ProjectTracking = () => {
    return (
        <div className="project-tracking-container">
            <section className="intro-section">
                <h1>Suivi de Chantier</h1>
                <p>Découvrez comment notre solution de suivi de chantier peut automatiser vos processus, optimiser la gestion de vos projets et offrir une expérience utilisateur exceptionnelle.</p>
            </section>
            <section className="benefits-section">
                <h2>Bénéfices du Suivi de Chantier</h2>
                <div className="benefit">
                    <img src={AutomationIcon} alt="Automatisation" />
                    <h3>Automatisation</h3>
                    <p>Automatisez les tâches répétitives et gagnez du temps précieux. Notre solution permet de réduire les erreurs humaines et d'améliorer l'efficacité opérationnelle.</p>
                </div>
                <div className="benefit">
                    <img src={OptimizationIcon} alt="Optimisation" />
                    <h3>Optimisation</h3>
                    <p>Optimisez la gestion de vos projets grâce à une visibilité complète et en temps réel. Prenez des décisions informées pour assurer le succès de vos chantiers.</p>
                </div>
                <div className="benefit">
                    <img src={UIUXIcon} alt="UI/UX" />
                    <h3>Expérience Utilisateur (UI/UX)</h3>
                    <p>Profitez d'une interface intuitive et facile à utiliser. Notre solution est conçue pour offrir une expérience utilisateur exceptionnelle, favorisant l'adoption et l'utilisation quotidienne.</p>
                </div>
            </section>
            <section className="cta-section">
                <h2>Prêt à Transformer votre Gestion de Chantier ?</h2>
                <p>Inscrivez-vous pour une démo gratuite et découvrez comment notre solution peut révolutionner la gestion de vos chantiers.</p>
                <Link to="/request-demo">
                    <button>Demander une démo</button>
                </Link>
            </section>
        </div>
    );
};

export default ProjectTracking;
