import React from 'react';
import './TimeTracking.scss';
import SaaSScreenshot from '../../Assets/images/home.png'; // Exemple de capture d'écran du SaaS
import TimeTrackingIcon from '../../Assets/images/home.png'; // Exemple d'icône de gestion du temps
import ReportingIcon from '../../Assets/images/home.png'; // Exemple d'icône de reporting
import IntegrationIcon from '../../Assets/images/home.png'; // Exemple d'icône d'intégration
import { Link } from 'react-router-dom';

const TimeTracking = () => {
    return (
        <div className="time-tracking-container">
            <section className="intro-section">
                <img src={SaaSScreenshot} alt="Capture d'écran du SaaS" className="screenshot" />
                <h1>Relevé d'Heures</h1>
                <p><strong>Améliorez la gestion de votre temps de travail</strong> avec notre solution de relevé d'heures. Suivez facilement le temps passé sur chaque projet, automatisez les rapports et intégrez les données avec vos outils existants.</p>
            </section>
            <section className="features-section">
                <h2>Fonctionnalités Clés</h2>
                <div className="feature">
                    <img src={TimeTrackingIcon} alt="Gestion du temps" />
                    <h3>Suivi du Temps en Temps Réel</h3>
                    <p><strong>Suivez le temps passé sur chaque tâche en temps réel</strong> avec une précision exceptionnelle. Notre solution vous permet de surveiller les activités en cours et d'optimiser l'allocation des ressources.</p>
                </div>
                <div className="feature">
                    <img src={ReportingIcon} alt="Reporting" />
                    <h3>Automatisation des Rapports</h3>
                    <p><strong>Générez automatiquement des rapports détaillés</strong> sur le temps passé, les coûts et l'efficacité. Utilisez ces données pour prendre des décisions éclairées et améliorer la productivité de votre équipe.</p>
                </div>
                <div className="feature">
                    <img src={IntegrationIcon} alt="Intégration" />
                    <h3>Intégration avec les Outils Existants</h3>
                    <p><strong>Intégrez facilement notre solution</strong> avec vos outils de gestion de projet et de paie existants. Assurez une transition fluide et maximisez l'efficacité de vos processus.</p>
                </div>
            </section>
            <section className="cta-section">
                <h2>Prêt à Optimiser la Gestion du Temps ?</h2>
                <p>Inscrivez-vous pour une démo gratuite et découvrez comment notre solution de relevé d'heures peut transformer la gestion du temps de votre entreprise.</p>
                <Link to="/request-demo">
                    <button>Demander une démo</button>
                </Link>
            </section>
        </div>
    );
};

export default TimeTracking;
