import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Register from './Pages/Register/Register';
import TopBar from './Components/TopBar/TopBar';
import TopBarMobile from './Components/TopBarMobile/TopBarMobile';
import BecomeATester from './Pages/BecomeATester/BecomeATester';
import ProjectTracking from './Pages/ProjectTracking/ProjectTracking';
import TimeTracking from './Pages/TimeTracking/TimeTracking';
import DocumentSharing from './Pages/DocumentSharing/DocumentSharing';
import Overview from './Pages/Overview/Overview';
import RequestDemo from './Pages/RequestDemo/RequestDemo';

function App() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Router>
            {isMobile ? <TopBarMobile /> : <TopBar />}
            <div className="main-content">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/become-a-tester/register" element={<Register />} />
                    <Route path='/become-a-tester' element={<BecomeATester />} />
                    <Route path='/project-tracking' element={<ProjectTracking />} />
                    <Route path='/overview' element={<Overview />} />
                    <Route path='/time-tracking' element={<TimeTracking />} />
                    <Route path='/document-sharing' element={<DocumentSharing />} />
                    <Route path='/request-demo' element={<RequestDemo />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
