import React, { useState } from 'react';
import './requestDemo.scss';


const RequestDemo = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [captchaValid, setCaptchaValid] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (captchaValid) {
            console.log('Nom:', name);
            console.log('Email:', email);
            console.log('Message:', message);

            // Reset form fields
            setName('');
            setEmail('');
            setMessage('');
        } else {
            alert('Veuillez vérifier que vous n\'êtes pas un robot.');
        }
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValid(true);
    };

    return (
        <div className="request-demo-container">
            <h1>Demander une Démo</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Nom</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    ></textarea>
                </div>
                <button type="submit">Envoyer</button>
            </form>
        </div>
    );
};

export default RequestDemo;
