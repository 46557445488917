import React, { useState } from 'react';
import './home.scss';
import HeroImage from '../../Assets/images/home.png';
import TestimonialImage from '../../Assets/images/home.png';
import { Construction, Timer, Description, EventNote } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const Home = () => {
    const [activeFeature, setActiveFeature] = useState(null);

    const handleFeatureClick = (feature) => {
        setActiveFeature(activeFeature === feature ? null : feature);
    };

    return (
        <div className="home-container">
            <section className="hero-section">
                <div className="content">
                    <h1>Transformez la gestion de vos chantiers avec Vision</h1>
                    <p>Découvrez comment notre outil peut simplifier vos opérations quotidiennes.</p>
                    <Link to="/request-demo">
                        <button className="cta-button">Essayer gratuitement</button>
                    </Link>
                </div>
                <div className="hero-image">
                    <img src={HeroImage} alt="Chantier en action" />
                </div>
            </section>
            <section className="features-section">
                <h2>Fonctionnalités Clés</h2>
                <div className="features">
                    <div className="feature" onClick={() => handleFeatureClick('tracking')}>
                        <Construction className="feature-icon" />
                        <h3>Suivi de chantier</h3>
                        <p>Un suivi détaillé et en temps réel de chaque phase de vos projets.</p>
                        {activeFeature === 'tracking' && (
                            <div className="feature-details">
                                <p>Grâce à Vision, vous pouvez suivre chaque phase de vos chantiers en temps réel, assurant une gestion efficace et proactive. Les fonctionnalités incluent la mise à jour automatique des progrès, la gestion des ressources et l'identification des obstacles potentiels avant qu'ils ne deviennent problématiques.</p>
                            </div>
                        )}
                    </div>
                    <div className="feature" onClick={() => handleFeatureClick('timeTracking')}>
                        <Timer className="feature-icon" />
                        <h3>Relevé d'heures</h3>
                        <p>Gérez les heures de travail efficacement et avec précision.</p>
                        {activeFeature === 'timeTracking' && (
                            <div className="feature-details">
                                <p>Notre outil permet une gestion précise des heures de travail, aidant à automatiser le suivi des présences et des absences, à générer des rapports d'heures détaillés et à intégrer ces données dans vos systèmes de paie existants.</p>
                            </div>
                        )}
                    </div>
                    <div className="feature" onClick={() => handleFeatureClick('documentSharing')}>
                        <Description className="feature-icon" />
                        <h3>Partage de documents</h3>
                        <p>Facilitez le partage de documents et la collaboration entre les équipes.</p>
                        {activeFeature === 'documentSharing' && (
                            <div className="feature-details">
                                <p>Le partage de documents en temps réel optimise la collaboration entre vos équipes. Vous pouvez facilement télécharger, partager et consulter des documents importants, réduisant ainsi les erreurs et assurant que tout le monde dispose des informations les plus récentes.</p>
                            </div>
                        )}
                    </div>
                    <div className="feature" onClick={() => handleFeatureClick('planning')}>
                        <EventNote className="feature-icon" />
                        <h3>Planning</h3>
                        <p>Planifiez vos projets avec des outils avancés.</p>
                        {activeFeature === 'planning' && (
                            <div className="feature-details">
                                <p>Nos outils de planification avancés vous permettent de créer des calendriers de projet détaillés, de fixer des échéances et de suivre les progrès par rapport aux objectifs. Vous pouvez ajuster les plans en temps réel en fonction des besoins du chantier.</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <section className="benefits-section">
                <h2>Les Avantages de Vision</h2>
                <div className="benefits">
                    <div className="benefit">
                        <h3>Optimisation des Chantiers</h3>
                        <p>Avec Vision, chaque étape de votre projet est suivie en temps réel, ce qui permet une meilleure gestion et une réduction des coûts.</p>
                    </div>
                    <div className="benefit">
                        <h3>Gestion du Temps</h3>
                        <p>Notre outil de relevé d'heures vous aide à gérer les heures de travail de vos équipes avec précision, augmentant ainsi l'efficacité globale.</p>
                    </div>
                    <div className="benefit">
                        <h3>Collaboration Améliorée</h3>
                        <p>Le partage de documents en temps réel facilite la collaboration entre les équipes, réduisant les erreurs et augmentant la productivité.</p>
                    </div>
                    <div className="benefit">
                        <h3>Planification Efficace</h3>
                        <p>Planifiez vos chantiers de manière efficace grâce à des outils de planification avancés, assurant que chaque projet reste sur la bonne voie.</p>
                    </div>
                    <div className="benefit">
                        <h3>Rapports et Analyses</h3>
                        <p>Générez des rapports détaillés et des analyses approfondies pour prendre des décisions éclairées et améliorer vos processus.</p>
                    </div>
                </div>
            </section>
            <section className="call-to-action-section">
                <h2>Prêt à transformer vos opérations ?</h2>
                <Link to="/request-demo">
                    <button className="cta-button">Contactez-nous</button>
                </Link>
            </section>
        </div>
    );
};

export default Home;
