import React, { useState } from 'react';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import './topBar.scss';
import { Link } from 'react-router-dom';
import logoimg from '../../Assets/images/vision.png';

const TopBar = () => {
    const [activeMain, setActiveMain] = useState(null);
    const [activeSub, setActiveSub] = useState(null);

    const handleMainClick = (event, menuName) => {
        event.stopPropagation();
        if (activeMain === menuName) {
            setActiveMain(null);
            setActiveSub(null);
        } else {
            setActiveMain(menuName);
            setActiveSub(null);
        }
    };

    const handleSubClick = (event, menuName) => {
        event.stopPropagation();
        if (activeSub === menuName) {
            setActiveSub(null);
        } else {
            setActiveSub(menuName);
        }
    };

    const resetMenu = () => {
        setActiveMain(null);
        setActiveSub(null);
    };

    return (
        <header className="top-bar">
            <div className="logo">
                <a href="/" onClick={resetMenu}>V I S I O N</a>
            </div>
            <nav>
                <ul className="menu">
                   {/*<li onClick={(e) => handleMainClick(e, 'solutions')}>
                        <span className="menu-title">Solutions</span> {activeMain === 'solutions' ? <ExpandLess /> : <ExpandMore />}
                        {activeMain === 'solutions' && (
                            <ul className="submenu">
                                <li onClick={(e) => handleSubClick(e, 'pre-construction')}>
                                    <span className="menu-title">Fonctionnalités</span> {activeSub === 'pre-construction' ? <ExpandLess /> : <ExpandMore />}
                                    {activeSub === 'pre-construction' && (
                                        <ul className="sub-submenu">
                                            <Link to="/overview" onClick={resetMenu}>
                                                <li>
                                                    <span className="menu-title">Résumé</span>
                                                    <p className="menu-description">Toutes les fonctionnalités en un coup d'œil.</p>
                                                </li>
                                            </Link>
                                            <Link to="/project-tracking" onClick={resetMenu}>
                                                <li>
                                                    <span className="menu-title">Suivi de Chantier</span>
                                                    <p className="menu-description">Suivi et optimisation des projets.</p>
                                                </li>
                                            </Link>
                                            <Link to="/time-tracking" onClick={resetMenu}>
                                                <li>
                                                    <span className="menu-title">Relevé d'Heures</span>
                                                    <p className="menu-description">Gestion et automatisation des heures.</p>
                                                </li>
                                            </Link>
                                            <Link to="/document-sharing" onClick={resetMenu}>
                                                <li>
                                                    <span className="menu-title">Partage de Documents</span>
                                                    <p className="menu-description">Partage de documents en temps réel.</p>
                                                </li>
                                            </Link>
                                        </ul>
                                    )}
                                </li>
                            </ul>
                        )}
                    </li>*/}
                    <Link to="/overview" onClick={resetMenu}>
                        <li>
                            <span className="menu-title">Résumé</span>
                        </li>
                    </Link>
                    <Link to="/about" onClick={resetMenu}>
                        <li>
                            <span className="menu-title">À propos</span>
                        </li>
                    </Link>
                    <Link to="/become-a-tester" onClick={resetMenu}>
                        <li>
                            <span className="menu-title">Devenir Testeur</span>
                        </li>
                    </Link>

                </ul>
            </nav>
            <div className="actions">
                <Link to="/request-demo">
                    <button>Demander une démo</button>
                </Link>
                <button onClick={() => window.location.href = 'https://app.vision-team-build.com'}>Connexion</button>
            </div>
        </header>
    );
};

export default TopBar;
