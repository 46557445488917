import React from 'react';
import { Link } from 'react-router-dom';
import './becomeATester.scss';

const BecomeATester = () => {
    return (
        <div className="tester-container">
            <section className="intro-section">
                <h1>Devenir Testeur pour Vision</h1>
                <p>Rejoignez notre programme de testeurs et contribuez à améliorer notre plateforme. En tant que testeur, vous aurez l'opportunité d'influencer le développement de Vision et de bénéficier d'avantages exclusifs.</p>
            </section>
            <section className="how-to-participate-section">
                <h2>Comment Participer</h2>
                <p>Pour devenir testeur, suivez ces étapes simples :</p>
                <ol>
                    <li>Inscrivez-vous en remplissant le formulaire d'inscription.</li>
                    <li>Recevez un email de confirmation avec les instructions de test.</li>
                    <li>Commencez à utiliser Vision et donnez-nous votre feedback.</li>
                </ol>
                <Link to="/become-a-tester/register">
                    <button className="cta-button">S'inscrire maintenant</button>
                </Link>
            </section>
            <section className="benefits-section">
                <h2>Avantages de Devenir Testeur</h2>
                <div className="benefits-list">
                    <div className="benefit">
                        <h3>Accès Précoce</h3>
                        <p>Essayez les nouvelles fonctionnalités avant tout le monde.</p>
                    </div>
                    <div className="benefit">
                        <h3>Influencez le Développement</h3>
                        <p>Partagez vos idées et aidez à façonner l'avenir de Vision.</p>
                    </div>
                    <div className="benefit">
                        <h3>Support Exclusif</h3>
                        <p>Bénéficiez d'un support prioritaire de notre équipe.</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BecomeATester;
