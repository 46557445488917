import React from 'react';
import './overview.scss';
import ChatIcon from '@mui/icons-material/Chat';
import DescriptionIcon from '@mui/icons-material/Description';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StorageIcon from '@mui/icons-material/Storage';
import TimerIcon from '@mui/icons-material/Timer';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { Link } from 'react-router-dom';

const Overview = () => {
    return (
        <div className="overview-container">
            <section className="intro-section">
                <h1>Résumé des Fonctionnalités de Vision</h1>
                <p>Découvrez toutes les fonctionnalités de notre SaaS Vision qui facilitent la gestion de vos chantiers et optimisent votre productivité.</p>
            </section>
            <section className="features-section">
                <div className="feature">
                    <ChatIcon className="feature-icon" />
                    <h3>Chat par Affaire et Utilisateur</h3>
                    <p><strong>Améliorez la communication</strong> grâce à notre système de chat intégré qui permet des échanges fluides par projet et par utilisateur.</p>
                </div>
                <div className="feature">
                    <DescriptionIcon className="feature-icon" />
                    <h3>Partage des Documents et Photos Terrain Chantier</h3>
                    <p><strong>Partagez facilement</strong> des documents et des photos directement depuis le terrain, pour une collaboration efficace et en temps réel.</p>
                </div>
                <div className="feature">
                    <SyncAltIcon className="feature-icon" />
                    <h3>Favorise les Échanges</h3>
                    <p><strong>Renforcez la collaboration</strong> entre les membres de votre équipe en facilitant les échanges d'informations et de feedbacks.</p>
                </div>
                <div className="feature">
                    <CalendarTodayIcon className="feature-icon" />
                    <h3>Planification des Chantiers</h3>
                    <p><strong>Optimisez la planification</strong> de vos projets grâce à des outils de gestion de tâches et de ressources adaptés à vos besoins.</p>
                </div>
                <div className="feature">
                    <AccessTimeIcon className="feature-icon" />
                    <h3>Planning en Temps Réel</h3>
                    <p><strong>Suivez l'avancement</strong> de vos chantiers en temps réel et ajustez vos plannings en fonction des besoins et des imprévus.</p>
                </div>
                <div className="feature">
                    <StorageIcon className="feature-icon" />
                    <h3>Dématérialisation</h3>
                    <p><strong>Réduisez l'utilisation du papier</strong> en dématérialisant vos processus administratifs, pour une gestion plus écologique et plus rapide.</p>
                </div>
                <div className="feature">
                    <TimerIcon className="feature-icon" />
                    <h3>Feuilles d'Heures</h3>
                    <p><strong>Gérez les heures de travail</strong> de votre équipe avec précision grâce à notre système de relevé d'heures intégré.</p>
                </div>
                <div className="feature">
                    <ReportProblemIcon className="feature-icon" />
                    <h3>Remontée des Problèmes</h3>
                    <p><strong>Identifiez et résolvez rapidement</strong> les problèmes sur le terrain grâce à notre outil de suivi des incidents et des anomalies.</p>
                </div>
                <div className="feature">
                    <ChecklistIcon className="feature-icon" />
                    <h3>Listes à Faire et à Prendre</h3>
                    <p><strong>Préparez vos chantiers</strong> de manière efficace avec des checklists personnalisées pour chaque projet.</p>
                </div>
            </section>
            <section className="cta-section">
                <h2>Prêt à Découvrir Tout le Potentiel de Vision ?</h2>
                <p>Inscrivez-vous pour une démo gratuite et explorez comment notre SaaS peut transformer la gestion de vos chantiers.</p>
                <Link to="/request-demo">
                    <button>Demander une démo</button>
                </Link>
            </section>
        </div>
    );
};

export default Overview;
