import React from 'react';
import './about.scss';

const About = () => {
    return (
        <div className="about-container">
            <section className="intro-section">
                <h1>Qui sommes-nous</h1>
                <p>Nous sommes une équipe passionnée dédiée à révolutionner la gestion de chantier dans le secteur du bâtiment.</p>
            </section>
            <section className="history-section">
                <h2>Notre Histoire</h2>
                <p>Vision a été fondée par des professionnels ayant une expérience de terrain dans le bâtiment. Après des années à travailler sur les chantiers et dans les bureaux, ils ont reconnu la nécessité de centraliser les informations importantes. Avec l'aide d'une équipe de développeurs, ils ont créé une solution SaaS qui répond aux besoins réels du terrain.</p>
            </section>
            <section className="mission-section">
                <h2>Notre Mission</h2>
                <p>Notre mission est de fournir des outils intuitifs et puissants pour améliorer la communication, la productivité et la sécurité sur les chantiers.</p>
            </section>
            <section className="values-section">
                <h2>Nos Valeurs</h2>
                <ul>
                    <li><strong>Innovation:</strong> Nous croyons en l'importance de l'innovation pour transformer le secteur de la construction.</li>
                    <li><strong>Qualité:</strong> Nous nous engageons à fournir des solutions de la plus haute qualité pour nos utilisateurs.</li>
                    <li><strong>Fiabilité:</strong> Nos outils sont conçus pour être fiables et efficaces, soutenant les projets de construction du début à la fin.</li>
                    <li><strong>Collaboration:</strong> Nous valorisons la collaboration et la communication pour garantir le succès des projets de nos clients.</li>
                </ul>
            </section>
            <section className="team-section">
                <h2>Notre Équipe</h2>
                <p>Nous sommes une équipe de développeurs, designers, et experts du bâtiment engagés à transformer le secteur de la construction grâce à la technologie.</p>
            </section>
        </div>
    );
};

export default About;

